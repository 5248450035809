import { default as contact_usuNZMpJOVlKMeta } from "/saham/pages/admin/contact_us.vue?macro=true";
import { default as forgot_45passwordu7oAUVEDpTMeta } from "/saham/pages/admin/forgot-password.vue?macro=true";
import { default as indexFro0GEk7kDMeta } from "/saham/pages/admin/index.vue?macro=true";
import { default as posts0VVIWjAQ8FMeta } from "/saham/pages/admin/posts.vue?macro=true";
import { default as reservationsQNt5kscfD2Meta } from "/saham/pages/admin/reservations.vue?macro=true";
import { default as servicesS2yiQbPwJsMeta } from "/saham/pages/admin/services.vue?macro=true";
import { default as areaHkFPFMuEJsMeta } from "/saham/pages/admin/settings/area.vue?macro=true";
import { default as email0rDkWvvNAJMeta } from "/saham/pages/admin/settings/email.vue?macro=true";
import { default as home_defaultDoM5uZGNCUMeta } from "/saham/pages/admin/settings/home_default.vue?macro=true";
import { default as profilehrHwQqVUElMeta } from "/saham/pages/admin/settings/profile.vue?macro=true";
import { default as siteD5Xf5HM4CCMeta } from "/saham/pages/admin/settings/site.vue?macro=true";
import { default as subscriptionsr2ZL2ZSGgUMeta } from "/saham/pages/admin/subscriptions.vue?macro=true";
import { default as videos5NWc55NPGrMeta } from "/saham/pages/admin/videos.vue?macro=true";
import { default as contactdIBTwiDVTsMeta } from "/saham/pages/contact.vue?macro=true";
import { default as indexAzQNlPc91nMeta } from "/saham/pages/index.vue?macro=true";
import { default as loginZXkm2Jcb6mMeta } from "/saham/pages/login.vue?macro=true";
import { default as _91id_930BTpEv782BMeta } from "/saham/pages/post/[id].vue?macro=true";
import { default as postsbZJwJZqJRDMeta } from "/saham/pages/posts.vue?macro=true";
import { default as reservationsp6ArhcI18Meta } from "/saham/pages/reservation.vue?macro=true";
import { default as _91token_93nsUO7LBPDmMeta } from "/saham/pages/resetpassword/[token].vue?macro=true";
import { default as servicesaknuGfwwdCMeta } from "/saham/pages/services.vue?macro=true";
import { default as testvoZktQtDmOMeta } from "/saham/pages/test.vue?macro=true";
export default [
  {
    name: "admin-contact_us",
    path: "/admin/contact_us",
    meta: contact_usuNZMpJOVlKMeta || {},
    component: () => import("/saham/pages/admin/contact_us.vue")
  },
  {
    name: "admin-forgot-password",
    path: "/admin/forgot-password",
    meta: forgot_45passwordu7oAUVEDpTMeta || {},
    component: () => import("/saham/pages/admin/forgot-password.vue")
  },
  {
    name: "Admin",
    path: "/admin",
    meta: indexFro0GEk7kDMeta || {},
    component: () => import("/saham/pages/admin/index.vue")
  },
  {
    name: "admin-posts",
    path: "/admin/posts",
    meta: posts0VVIWjAQ8FMeta || {},
    component: () => import("/saham/pages/admin/posts.vue")
  },
  {
    name: "admin-reservations",
    path: "/admin/reservations",
    meta: reservationsQNt5kscfD2Meta || {},
    component: () => import("/saham/pages/admin/reservations.vue")
  },
  {
    name: "admin-services",
    path: "/admin/services",
    meta: servicesS2yiQbPwJsMeta || {},
    component: () => import("/saham/pages/admin/services.vue")
  },
  {
    name: "admin-settings-area",
    path: "/admin/settings/area",
    meta: areaHkFPFMuEJsMeta || {},
    component: () => import("/saham/pages/admin/settings/area.vue")
  },
  {
    name: "admin-settings-email",
    path: "/admin/settings/email",
    meta: email0rDkWvvNAJMeta || {},
    component: () => import("/saham/pages/admin/settings/email.vue")
  },
  {
    name: "admin-settings-home_default",
    path: "/admin/settings/home_default",
    meta: home_defaultDoM5uZGNCUMeta || {},
    component: () => import("/saham/pages/admin/settings/home_default.vue")
  },
  {
    name: "admin-settings-profile",
    path: "/admin/settings/profile",
    meta: profilehrHwQqVUElMeta || {},
    component: () => import("/saham/pages/admin/settings/profile.vue")
  },
  {
    name: "admin-settings-site",
    path: "/admin/settings/site",
    meta: siteD5Xf5HM4CCMeta || {},
    component: () => import("/saham/pages/admin/settings/site.vue")
  },
  {
    name: "admin-subscriptions",
    path: "/admin/subscriptions",
    meta: subscriptionsr2ZL2ZSGgUMeta || {},
    component: () => import("/saham/pages/admin/subscriptions.vue")
  },
  {
    name: "admin-videos",
    path: "/admin/videos",
    meta: videos5NWc55NPGrMeta || {},
    component: () => import("/saham/pages/admin/videos.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/saham/pages/contact.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/saham/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginZXkm2Jcb6mMeta || {},
    component: () => import("/saham/pages/login.vue")
  },
  {
    name: "post-id",
    path: "/post/:id()",
    component: () => import("/saham/pages/post/[id].vue")
  },
  {
    name: "posts",
    path: "/posts",
    component: () => import("/saham/pages/posts.vue")
  },
  {
    name: "reservation",
    path: "/reservation",
    component: () => import("/saham/pages/reservation.vue")
  },
  {
    name: "resetpassword-token",
    path: "/resetpassword/:token()",
    meta: _91token_93nsUO7LBPDmMeta || {},
    component: () => import("/saham/pages/resetpassword/[token].vue")
  },
  {
    name: "services",
    path: "/services",
    component: () => import("/saham/pages/services.vue")
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/saham/pages/test.vue")
  }
]